<template>
	<main id="container" class="page-container">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="이벤트" />
				</div>
			</div>
		</div>
		<div class="page-content event-check">
			<div class="container">
				<div class="page-sub-header">
					<h3 class="page-sub-title"><span class="prima">동행축제 이벤트에 참여하고</span> 푸짐한 선물 받으세요.</h3>
				</div>
			</div>
			<div class="page-tab-container">
				<div class="container tab-buttons">
					<router-link to="check" class="tab-button is-active">출석체크</router-link>
					<router-link to="dibs" class="tab-button">찜하기</router-link>
					<router-link to="sns" class="tab-button">구매후기</router-link>
				</div>
			</div>
			<div class="container">
				<div class="event-check-container">
					<div class="event-check-header">
						<div class="event-check-header-banner">
							<div class="banner-titlewrap">
								<span class="banner-title">2024년 7~8월</span>
								<span class="banner-title">출석체크 이벤트</span>
							</div>
							<div class="banner-img">
								<img src="@/assets/img/event/banner_icon03.jpg" alt="" />
							</div>
						</div>
						<div class="event-check-attendance">
							<div class="attendance-table">
								<table class="table">
									<caption>
										일,월,화,수,목,금,토 항목의 출석체크 표
									</caption>
									<colgroup>
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
										<col style="width: 14.28%" />
									</colgroup>
									<thead>
										<tr class="table-week-header">
											<th v-for="(week, idx) in weekList" :key="idx">
												<div class="table-week-container">
													<span class="week-name">{{ week.name }}</span>
													<span class="week-name-eng">{{ week.eng }}</span>
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(week, idx) in dateMatrix" :key="idx" :class="{ 'is-border': week.some((item) => String(item.date).includes('/1')) }">
											<td v-for="date in week" :key="date.day">
												<div v-if="date.day === new Date().toISOString().split('T')[0] && date.event === undefined">
													<button type="button" class="button-check-attend" @click.prevent="clickCheck(date.day)">출석</button>
												</div>
												<div v-else>
													<div v-if="date.event === undefined" class="day-num">
														{{ date.date }}
													</div>
													<div v-if="date.event" class="day-num-icon">
														<i v-if="date.event.heart" class="icon-attend-heart">
															<span class="sr-only">출석 체크</span>
														</i>
														<i v-else class="icon-attend-check"><span class="sr-only">출석 체크</span></i>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="event-check-header-info">
							<ul class="event-check-header-info-list">
								<li v-for="(item, idx) in infoList" :key="idx">
									<span class="infolist-title">{{ item.title }}</span>
									<span class="infolist-text">{{ item.detail }}</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="event-check-guide">
						<span class="event-check-guide-title">참여방법</span>
						<div class="event-check-guide-desc">
							<ul class="event-check-guide-desclist">
								<li class="event-check-guide-desclist-li">
									<div class="event-check-guide-desclist-img">
										<img src="@/assets/img/event/icon_img06.png" alt="" />
									</div>
									<div class="event-check-guide-desclist-text">
										<span class="desclist-title">STEP 1</span>
										<span class="desclist-text">출석체크 이벤트 참여 시, 본인인증을 통한 로그인은 필수이며 1일 1회 출석가능 합니다.</span>
									</div>
								</li>
								<li class="event-check-guide-desclist-li">
									<div class="event-check-guide-desclist-img">
										<img src="@/assets/img/event/icon_img07.png" alt="" />
									</div>
									<div class="event-check-guide-desclist-text">
										<span class="desclist-title">STEP 2</span>
										<span class="desclist-text">상단의 달력에서 당일 날짜를 선택하면 출석 표시가 됩니다.</span>
										<span class="desclist-text"><span class="boldtxt">7일 출석 시, 하트가 1개 생기며 경품 추첨에 응모됩니다.</span> 하트가 많을수록 당첨 확률이 올라갑니다.</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div class="event-check-products">
						<span class="event-check-products-title">경품소개</span>
						<ul class="event-check-products-list">
							<li class="event-check-products-list-li" v-for="(item, idx) in prdList" :key="idx">
								<div class="event-check-products-item">
									<div class="event-check-products-item-img">
										<img :src="require(`@/assets/img/main/${item.img}`)" :alt="item.title" />
									</div>
									<div class="event-check-products-item-title">
										<span class="item-title">{{ item.title }}</span>
										<span class="item-title boldtxt">{{ item.person }}</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="event-check-prect">
				<div class="container">
					<span class="event-check-prect-title">유의사항</span>
					<ul class="event-check-prect-list">
						<li class="event-check-prect-list-li" v-for="(item, idx) in prectList" :key="idx">
							<span class="prect-list-text">{{ item }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<event-terms-modal :visible="visibleModal" @closeModal="visibleModal = false" />
	</main>
</template>

<script>
import { MUT_SHOW_ALERT, MUT_SHOW_CONFIRM, MUT_SHOW_REVIEW_MODAL, MUT_SET_RETURN_ROUTE } from '@/store/_mut_consts';
import PageHeader from '@/components/content/PageHeader';
import EventTermsModal from '@/components/content/EventTermsModal';

export default {
	name: 'EventCheck',
	components: { PageHeader, EventTermsModal },
	data: () => ({
		visibleModal: true,
		weekList: [
			{
				name: '일',
				eng: 'SUN',
			},
			{
				name: '월',
				eng: 'MON',
			},
			{
				name: '화',
				eng: 'TUE',
			},
			{
				name: '수',
				eng: 'WED',
			},
			{
				name: '목',
				eng: 'THU',
			},
			{
				name: '금',
				eng: 'FRI',
			},
			{
				name: '토',
				eng: 'SAT',
			},
		],
		startDate: new Date('2024-06-20'), // 시작 날짜
		endDate: new Date('2024-07-30'), // 종료 날짜
		events: [
			{ date: '2024-06-25', check: true, heart: false },
			{ date: '2024-07-02', check: true, heart: false },
			{ date: '2024-07-03', check: true, heart: true },
			{ date: '2024-07-05', check: true, heart: false },
			{ date: '2024-07-06', check: true, heart: false },
		],
		infoList: [
			{
				title: '참여기간',
				detail: '2024-09-00(월) ~ 2024-09-00(일)',
			},
			{
				title: '결과발표',
				detail: '2024년 10월 중',
			},
			{
				title: '경품발송',
				detail: '2024년 11월 중',
			},
		],
		prdList: [
			{
				img: 'item_image01.jpg',
				title: '갤럭시 탭 S9',
				person: '(1명)',
			},
			{
				img: 'item_image02.jpg',
				title: '아이스크림 케이크 기프티콘',
				person: '(5명)',
			},
			{
				img: 'item_image03.jpg',
				title: '치킨 기프티콘',
				person: '(10명)',
			},
			{
				img: 'item_image04.jpg',
				title: '온누리상품권 1만 원권',
				person: '(15명)',
			},
		],
		prectList: ['휴대폰 인증을 하셔야 참여할 수 있습니다.', '달력의 날짜를 클릭해서 출석 표시가 되어야 이벤트 참여로 인정됩니다.', '출석체크 참여도가 높을수록 동행제품 찜하기 이벤트 당첨 확률이 올라갑니다.', '당첨 후 경품의 제세 공과금(22%)는 본인이 부담해야 합니다.', '당첨자는 이벤트 종료 후 1개월 이내 공지될 예정입니다.', '당첨자의 잘못된 정보 입력으로 인해 경품 누락이 될 경우 재 발송되지 않습니다.', '해당 이벤트에 14세 미만은 참여할 수 없습니다.'],
	}),
	computed: {
		dateMatrix() {
			let dates = [];
			let currentDate = new Date(this.startDate);
			const startDate = new Date(this.startDate);
			const startDateStr = this.startDate.toISOString().split('T')[0];

			// 시작 날짜부터 종료 날짜까지의 날짜 목록 생성
			while (currentDate <= this.endDate) {
				const dateString = currentDate.toISOString().split('T')[0];
				// 헤당 일자의 이벤트를 찾음
				const event = this.events.find((event) => event.date === dateString);
				if (Number(currentDate.getDate()) === 1 || dateString === startDateStr) {
					let strMonth = currentDate.getMonth() + 1;
					let strDay = currentDate.getDate();
					dates.push({ day: dateString, date: `${strMonth}/${strDay}`, event });
				} else {
					dates.push({ day: dateString, date: currentDate.getDate(), event });
				}

				currentDate.setDate(currentDate.getDate() + 1);
			}

			// 날짜 목록을 주 단위로 분할
			const weeks = [];
			let week = new Array(7).fill({ date: '', event: null });

			dates.forEach((date, index) => {
				let dateDay = new Date(date.day);
				const dayOfWeek = new Date(dateDay.getFullYear(), dateDay.getMonth(), dateDay.getDate()).getDay();
				week[dayOfWeek] = date;

				// 해당 월의 마지막 날짜 구하기
				const lastDateOfMonth = new Date(dateDay.getFullYear(), dateDay.getMonth() + 1, 0).getDate();

				if (date.date === lastDateOfMonth) {
					const remainingDays = 7 - dayOfWeek - 1;
					for (let i = 0; i < remainingDays; i++) {
						week[dayOfWeek + i + 1] = { date: '', event: null };
					}
					weeks.push(week);
					week = new Array(7).fill({ date: '', event: null });
				} else if (dayOfWeek === 6 || index === dates.length - 1) {
					weeks.push(week);
					week = new Array(7).fill({ date: '', event: null });
				}

				// if (dayOfWeek === 6 || index === dates.length - 1) {
				// 	weeks.push(week);
				// 	week = new Array(7).fill({ date: '', event: null });
				// }
			});

			return weeks;
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		clickCheck(date) {
			this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
				title: '로그인 후 참여가능합니다.\r로그인 하시겠습니까?',
				showCancelButton: true,
				yesfunc: () => {
					const tempEvent = {
						date: date,
						checks: true,
						hearts: false,
					};
					this.events.push(tempEvent);
				},
				nofunc: () => {},
			});
		},
	},
};
</script>
